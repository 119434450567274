body {
  font-family: "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hasklig https://github.com/i-tu/Hasklig */
/* Fira Code https://github.com/tonsky/FiraCode */
/* Ligaturizer https://github.com/ToxicFrog/Ligaturizer */
.branch-name,
.blob-num,
.blob-code-inner,
.CodeMirror pre,
.commit .sha,
.commit-desc pre,
.commit-ref,
.file-info,
.gollum-editor .gollum-editor-body,
.input-monospace, .rgh-monospace-textareas .comment-form-textarea,
input.url-field,
pre,
tt,
code {
  font-family: "Fira Code", Hack, Hasklig, source-code-pro, "SF Mono", "Operator Mono Lig", "LigaInconsolata", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Monaco, "Courier New", monospace;
  text-rendering: optimizeLegibility;
}
