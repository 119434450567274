iframe.responsive {
  width: 100% !important;
}

// TODO: Brainstorm where these styles should be saved
@media only screen and (max-width: 626px) {
  .blu-x-header {
    .main-container {
      .subtitle {
        display: none;
      }
    }
  }

  .modal {
    padding: 10px;
    width: calc(100% - 20px) !important;

    .modal-content {
      width: 100% !important;

      .modal-header {
        h1 {
          font-size: 1.3em;
        }
      }
    }
  }
}
