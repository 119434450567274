/* Align styles */
.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

/* Display styles */
.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
}

.display-grid {
  display: grid;
}

/* Height styles */
.custom-min-height-120 {
  min-height: 120px;
}

.custom-min-height-full {
  min-height: 100%;
}

.custom-height-40 {
  height: 40%;
}

.custom-height-15 {
  height: 15%;
}

.custom-height-75 {
  height: 75%;
}

.custom-height-10 {
  height: 10%;
}

.custom-height-60 {
  height: 60%;
}

.custom-height-100 {
  height: 100%;
}