.button-group {
  display: inline-block;
  border: 1px solid rgb(127, 203, 238);
}

button {
  &.btn,
  &.button {
    border-radius: 0 !important;

    &:active,
    &:focus,
    &:hover {
      background-color: rgba(124, 198, 232, 0.247);
    }

    &.default {
      background-color: rgba(124, 198, 232, 1);
      color: #ffffff;
    }

    &.has-white-text {
      color: #ffffff;

      &:hover,
      &.is-hovered {
        color: #b6b6b6;
      }
    }
  }
}
