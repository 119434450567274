@import "base/reset";
@import "base/normalize";
@import "base/typography";
@import "elements/buttons";
@import "elements/custom";
@import "layout/responsive";

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

#root {
  width: 100%;
  height: 100%;
}

iframe {
  display: block;

  &:focus {
    outline: none;
  }

  &[seamless] {
    display: block;
  }
}
