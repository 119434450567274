.app {
  width: 100%;
  height: 100%;

  #loginForm {
    width: 350px;
    padding: 0;
    margin: auto;
    background: white;
    border: 1px solid lightgrey;
    font-size: 14px;
    box-shadow: 1px 1px 10px lightgrey;
    -webkit-box-shadow: 1px 1px 10px lightgrey;
  
    #formBody {
      // padding: 10px 20px;
  
      // label[for="username"],
      // label[for="password"] {
      //   display: block;
      //   width: 100%;
      //   padding: 5px;
      //   box-sizing: border-box;
      // }
  
      // input {
      //   display: block;
      //   width: 100%;
      //   padding: 8px 6px;
      //   margin: 0;
      //   box-sizing: border-box;
      //   border: 6px solid #f7f9fa;
      //   box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.06) inset, 0 0 1px #95a2a7 inset;
      //   -webkit-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.06) inset, 0 0 1px #95a2a7 inset;
      // }
  
      #message {
        &.error {
          padding: 10px;
          color: white;
          margin: 10px 0;
          background-color: red;
        }
  
        &.success {
          padding: 10px;
          color: white;
          margin: 10px 0;
          background-color: green;
        }
      }
    }
  
    #formFooter {
      // background: #eff4f6;
      // border-top: 1px solid white;
      // padding: 22px 26px;
      // overflow: hidden;
      // height: 32px;
  
      button[type="submit"] {
        // float: right;
        // background: -webkit-gradient(linear, left top, left bottom, from(#7acbed), to(#badff3));
        cursor: pointer;
        // border-radius: 15px;
        // border: 1px solid #7db0cc;
        // font-weight: bold;
        // text-shadow: rgba(0, 0, 0, 0.2) 0 1px 0px;
        // padding: 7px 21px;
        // color: white;
        // box-shadow: inset rgba(255, 255, 255, 0.6) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 1px 1px;
        // -webkit-box-shadow: inset rgba(255, 255, 255, 0.6) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 1px 1px;
      }
    }
  }
  
  footer.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 20px 0;
    font-size: 12px;
    width: 100%;
  }
}